import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import MobileHeader from "../components/mobileHeader";
import { Link } from "react-router-dom";
import Banner from "../components/homepage/banner";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";
import CommonBanner from "../components/commonBanner";
import AboutUsServiceCard from "../components/abputUs/aboutUsServiceCard";

const Products = () => {

  return (
    <>
      <div className="main-wrapper">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Products | AppDoor | think digital</title>
          <link rel="canonical" href="https://appdoor.co.in/" />
          <meta
            name="description"
            content="Appdoor | think digital - Welcome to Appdoor, where innovation meets excellence! We are a passionate team of tech enthusiasts on a mission to redefine the digital landscape and empower businesses with cutting-edge solutions."
          />
        </Helmet>

        <Header activePage={"products"} />

        {/* Offcanvas Start*/}
        <MobileHeader />
        {/* Offcanvas End */}

        <CommonBanner bannerTitle="Products" />

        <div className="section techwix-blog-grid-section section-padding">
            <div className="container">
                <div className="techwix-blog-grid-wrap">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-blog">
                                <div className="blog-image">
                                    <Link to="#" ><img src="assets/images/jdcivils.jpg" alt="jdcivils" /></Link>
                                </div>
                         
                            </div>
                        </div>
                   
                    </div>
                </div>
            </div>
        </div>

        


        <Footer  />
      </div>
    </>
  );
};

export default Products;
