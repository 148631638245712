import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import MobileHeader from "../components/mobileHeader";
import { Link } from "react-router-dom";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";
import CommonBanner from "../components/commonBanner";
import AboutUsServiceCard from "../components/abputUs/aboutUsServiceCard";

function Home() {


  return (
    <>
      <div className="main-wrapper" >
        <Helmet>
          <meta charSet="utf-8" />
          <title>About Us | AppDoor | think digital</title>
          <link rel="canonical" href="https://appdoor.co.in/" />
          <meta
            name="description"
            content="Appdoor | think digital - Welcome to Appdoor, where innovation meets excellence! We are a passionate team of tech enthusiasts on a mission to redefine the digital landscape and empower businesses with cutting-edge solutions."
          />
        </Helmet>
        {/* <Preloader/> */}

        <Header activePage={"about"} />

        {/* Offcanvas Start*/}
        <MobileHeader />
        {/* Offcanvas End */}

        <CommonBanner bannerTitle="About Us" />

        {/* <!-- About Start --> */}
        <div className="section techwix-about-section-07 section-padding">
          <div className="shape-1"></div>
          <div className="container">
            {/* <!-- About Wrapper Start --> */}
            <div className="about-wrap">
              <div className="row">
                <div className="col-lg-6">
                  {/* <!-- About Image Wrap Start --> */}
                  <div className="about-img-wrap">
                    <img
                      className="shape-1"
                      src="assets/images/shape/about-shape2.png"
                      alt=""
                    />
                    <div className="about-img">
                      <img src={"assets/images/about-3.jpg"} alt="" />
                    </div>
                    <div className="about-img about-img-2">
                      <img src={"assets/images/about-4.jpg"} alt="" />
                    </div>
                  </div>
                  {/* <!-- About Image Wrap End --> */}
                </div>
                <div className="col-lg-6">
                  {/* <!-- About Content Wrap Start --> */}
                  <div className="about-content-wrap">
                    <div className="section-title">
                      <h3 className="sub-title">Who we are</h3>
                      <h2 className="title">
                        Profoundly Custom-made IT Plan, The executives and
                        Backing Administrations.
                      </h2>
                    </div>
                    <p className="text">
                      Speed up advancement with elite tech groups We'll match
                      you to a whole remote group of inconceivable independent
                      ability for all your product improvement needs.
                    </p>
                    {/* <!-- About List Start --> */}
                    <div className="about-list-03">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="about-list-item-03">
                            <h3 className="title">Our Mission</h3>
                            <p>
                              Our mission is to harness technology's potential
                              for positive change, delivering innovative,
                              client-centered solutions while upholding
                              integrity, education, and social responsibility in
                              all we do.
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="about-list-item-03">
                            <h3 className="title">Our Vision</h3>
                            <p>
                              To be a global leader in technology innovation,
                              driving positive change through visionary
                              solutions. We aim to inspire a future where
                              technology empowers and enriches lives worldwide.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- About List End --> */}
                  </div>
                  {/* <!-- About Content Wrap End --> */}
                </div>
              </div>
            </div>
            {/* <!-- About Wrapper End --> */}
          </div>
        </div>
        {/* <!-- About End --> */}

        <div className="section techwix-counter-section-03 techwix-counter-section-04">
          <div className="container">
            <div
              className="counter-wrap"
              style={{
                backgroundImage: "url(assets/images/bg/counter-bg2.jpg)",
              }}
            >
              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  <div className="single-counter-02 text-center">
                    <span>42+</span>
                    <p>Completed Projects</p>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="single-counter-02 text-center">
                    <span>25+</span>
                    <p>Happy Clients</p>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="single-counter-02 text-center">
                    <span>7%</span>
                    <p>SKILLED EXPERTS</p>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="single-counter-02 text-center">
                    <span>261+</span>
                    <p>Clients Supoort Done</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="section techwix-choose-us-section section-padding"
          style={{ backgroundImage: "url(assets/images/bg/choose-us-bg3.jpg)" }}
        >
          <div className="container">
            <div className="choose-us-wrap">
              <div className="section-title text-center">
                <h3 className="sub-title">REASON TO CHOOSE US</h3>
                <h2 className="title">
                  We provide truly prominent IT solutions.
                </h2>
              </div>
              <div className="choose-us-content-wrap">
                <div className="row">

                    <AboutUsServiceCard serviceImg="assets/images/ser-icon9.png" serviceTitle="Innovation at the Core" description="We're driven by innovation, constantly pushing boundaries to create cutting-edge solutions that cater to your unique needs." linkto="#" />

                    <AboutUsServiceCard serviceImg="assets/images/counter-3.png" serviceTitle="Innovation at the Core" description="We're driven by innovation, constantly pushing boundaries to create cutting-edge solutions that cater to your unique needs." linkto="#" />

                    <AboutUsServiceCard serviceImg="assets/images/ser-icon10.png" serviceTitle="Expertise Matters" description="Our team comprises skilled professionals with diverse expertise, ensuring that every project we undertake is executed flawlessly." linkto="#" />

                    <AboutUsServiceCard serviceImg="assets/images/ser-icon11.png" serviceTitle="Client-Centric Approach" description="Your success is our priority. We listen, understand, and collaborate closely with you to deliver tailored solutions that exceed your expectations." linkto="service" />

                    <AboutUsServiceCard serviceImg="assets/images/ser-icon12.png" serviceTitle="Quality Assurance" description="We adhere to the highest industry standards, rigorously testing and refining our work to guarantee top-notch quality and reliability." linkto="#" />

                    <AboutUsServiceCard serviceImg="assets/images/counter-1.png" serviceTitle="Timely Delivery" description="We understand the value of your time. Our efficient project management ensures on-time delivery without compromising quality." linkto="#" />

                    <AboutUsServiceCard serviceImg="assets/images/ser-icon21.png" serviceTitle="Transparent Communication" description="We believe in open and honest communication. You'll always be informed about the progress of your project." linkto="#" />

                    <AboutUsServiceCard serviceImg="assets/images/ser-icon22.png" serviceTitle="Cost-Effective Solutions" description="We offer competitive pricing without compromising on quality, ensuring that you get the best value for your investment." linkto="#" />
                  
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Skill Start */}
        <div className="section techwix-skill-section-02 section-padding">
          <div className="container">
            <div className="skill-wrap">
              <div className="row">
                <div className="col-lg-6">
                  {/* Skill Left Start */}
                  <div className="skill-left">
                    <div className="section-title">
                      <h2 className="title">
                        Planning for your prosperity, we give really noticeable
                        IT arrangements
                      </h2>
                    </div>
                    <div className="experience-wrap">
                      <div className="experience">
                        <h2 className="number">13</h2>
                        <span>
                          Years of <br /> experience
                        </span>
                      </div>
                      <div className="experience-text">
                        <p>
                          Join hands with Appdoor, and together, we'll transform
                          your vision into reality. Experience the power of
                          technology like never before.
                        </p>
                        <Link className="learn-more" to="about">
                          learn More About Us{" "}
                          <i className="fas fa-long-arrow-alt-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* Skill Left End */}
                </div>
                <div className="col-lg-6">
                  {/* Skill Right Start */}
                  <div className="skill-right">
                    <div className="counter-bar">
                      {/* Skill Item Start */}
                      <div className="skill-item">
                        <span className="title">Software Development</span>
                        <div className="skill-bar">
                          <div className="bar-inner">
                            <div
                              className="bar progress-line color-1"
                              data-width={80}
                            >
                              <span className="skill-percent">
                                <span className="counter">80</span>%
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Skill Item End */}
                      {/* Skill Item Start */}
                      <div className="skill-item">
                        <span className="title">Website Development</span>
                        <div className="skill-bar">
                          <div className="bar-inner">
                            <div
                              className="bar progress-line color-1"
                              data-width={95}
                            >
                              <span className="skill-percent">
                                <span className="counter">95</span>%
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Skill Item End */}
                      {/* Skill Item Start */}
                      <div className="skill-item">
                        <span className="title">Mobile App Development</span>
                        <div className="skill-bar">
                          <div className="bar-inner">
                            <div
                              className="bar progress-line color-1"
                              data-width={80}
                            >
                              <span className="skill-percent">
                                <span className="counter">80</span>%
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Skill Item End */}
                      {/* Skill Item Start */}
                      <div className="skill-item">
                        <span className="title">Digital Marketing</span>
                        <div className="skill-bar">
                          <div className="bar-inner">
                            <div
                              className="bar progress-line color-1"
                              data-width={90}
                            >
                              <span className="skill-percent">
                                <span className="counter">90</span>%
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Skill Item End */}
                    </div>
                  </div>
                  {/* Skill Right End */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Skill End */}
        {/* Case Study Start */}
        <div
          className="section techwix-case-study-section-02 section-padding"
          style={{
            backgroundImage: "url(assets/images/bg/case-study-bg.jpg)",
          }}
        >
          <div className="container">
            <div className="case-study-wrap">
              <div className="section-title text-center">
                <h3 className="sub-title">From our Case studies</h3>
                <h2 className="title white">We delivered best solution</h2>
              </div>
            </div>
          </div>
          {/* Case Study Content Wrap Start */}
          <div className="case-study-content-wrap">
            <div className="swiper-container case-study-active">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  {/* Single Case Study Start */}
                  <div className="single-case-study-02">
                    <div className="case-study-img">
                      <Link to="#">
                        <img
                          src="assets/images/case-4.jpg"
                          alt="Quality Assurance"
                        />
                      </Link>
                    </div>
                    <div className="case-study-content">
                      <h3 className="title">
                        <Link to="#">
                          <span>Quality</span> <br /> <span>Assurance</span>
                        </Link>
                      </h3>
                      <p className="text">
                        We adhere to the highest industry standards, rigorously
                        testing and refining our work to guarantee top-notch
                        quality and reliability.
                      </p>
                    </div>
                  </div>
                  {/* Single Case Study End */}
                </div>
                <div className="swiper-slide">
                  {/* Single Case Study Start */}
                  <div className="single-case-study-02">
                    <div className="case-study-img">
                      <Link to="#">
                        <img
                          src="assets/images/case-5.jpg"
                          alt="Timely Deliver"
                        />
                      </Link>
                    </div>
                    <div className="case-study-content">
                      <h3 className="title">
                        <Link to="#">
                          <span>Timely </span> <br /> <span>Deliver</span>
                        </Link>
                      </h3>
                      <p className="text">
                        We understand the value of your time. Our efficient
                        project management ensures on-time delivery without
                        compromising quality.
                      </p>
                    </div>
                  </div>
                  {/* Single Case Study End */}
                </div>
                <div className="swiper-slide">
                  {/* Single Case Study Start */}
                  <div className="single-case-study-02">
                    <div className="case-study-img">
                      <Link to="#">
                        <img src="assets/images/case-6.jpg" alt="Scalability" />
                      </Link>
                    </div>
                    <div className="case-study-content">
                      <h3 className="title">
                        <Link to="#">
                          <span>Scalability</span> <br />{" "}
                        </Link>
                      </h3>
                      <p className="text">
                        Our solutions are designed to grow with your business,
                        providing the scalability needed to adapt and thrive in
                        a dynamic digital landscape.
                      </p>
                    </div>
                  </div>
                  {/* Single Case Study End */}
                </div>
              </div>
              {/* Add Pagination */}
              <div className="swiper-pagination" />
            </div>
          </div>
          {/* Case Study Content Wrap End */}
        </div>
        {/* Case Study End */}
        {/* Testimonial Start  */}
        <div className="section techwix-testimonial-section-02 techwix-testimonial-section-03 section-padding-02">
          <div className="container">
            {/* Testimonial Wrap Start  */}
            <div className="testimonial-wrap-02">
              <div className="section-title text-center">
                <h3 className="sub-title">Testimonial</h3>
                <h2 className="title">Our client's words</h2>
              </div>
              <div className="testimonial-content-wrap-02">
                <div className="swiper-container testimonial-02-active">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      {/*  Single Testimonial Start  */}
                      <div className="single-testimonial-02">
                        <div className="testimonial-thumb">
                          <img src="" alt="John D." />
                        </div>
                        <div className="testimonial-content">
                          <img
                            src="assets/images/testi-icon.png"
                            alt="John D."
                          />
                          <p>
                            "I can't thank Appdoor enough for their outstanding
                            work! Their team transformed our app idea into a
                            polished, user-friendly reality. Their attention to
                            detail and commitment to quality are unparalleled."
                          </p>
                          <span className="name">John D. </span>
                        </div>
                      </div>
                      {/*  Single Testimonial End  */}
                    </div>
                    <div className="swiper-slide">
                      {/*  Single Testimonial Start  */}
                      <div className="single-testimonial-02">
                        <div className="testimonial-thumb">
                          <img src="" alt="Sarah M." />
                        </div>
                        <div className="testimonial-content">
                          <img
                            src="assets/images/testi-icon.png"
                            alt="Sarah M."
                          />
                          <p>
                            "Appdoor's expertise is unmatched. They not only
                            built us a powerful e-commerce website but also
                            provided invaluable insights to improve our online
                            presence. Working with them was a game-changer."
                          </p>
                          <span className="name">Sarah M. </span>
                        </div>
                      </div>
                      {/*  Single Testimonial End  */}
                    </div>
                    <div className="swiper-slide">
                      {/*  Single Testimonial Start  */}
                      <div className="single-testimonial-02">
                        <div className="testimonial-thumb">
                          <img src="" alt="David L." />
                        </div>
                        <div className="testimonial-content">
                          <img
                            src="assets/images/testi-icon.png"
                            alt="David L."
                          />
                          <p>
                            "Choosing Appdoor was the best decision we made for
                            our startup. They delivered our project ahead of
                            schedule and within budget. Their communication
                            throughout the process was exceptional."
                          </p>
                          <span className="name">David L. </span>
                        </div>
                      </div>
                      {/*  Single Testimonial End  */}
                    </div>
                  </div>
                  {/* Add Pagination */}
                  <div className="swiper-pagination" />
                </div>
              </div>
            </div>
            {/* Testimonial Wrap End  */}
          </div>
        </div>
        {/* Testimonial End  */}
        {/* Brand Logo Start */}
        <div className="section techwix-brand-section techwix-brand-section-03">
          <div className="container">
            {/* Brand Wrapper Start */}
            <div className="brand-wrapper text-center">
              {/* Brand Active Start */}
              <div className="brand-active">
                <div className="swiper-container">
                  <div className="swiper-wrapper">
                    {/* Single Brand Start */}
                    <div className="swiper-slide single-brand">
                      <img
                        src="assets/images/odclogo.PNG"
                        alt="Odyssey Motors Pvt. Ltd."
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* Brand Active End */}
            </div>
            {/* Brand Wrapper End */}
          </div>
        </div>
        {/* Brand Logo End */}
       
        {/* Blog Start */}
        <div className="section techwix-blog-section section-padding-02">
          <div className="container">
            {/* Section Title Start */}
            <div className="section-title text-center">
              <h4 className="sub-title">latest Blog</h4>
              <h2 className="title">From the newsroom</h2>
            </div>
            {/* Section Title End */}
            <div className="techwix-blog-wrap">
              <div className="row">
                {/* <div className="col-lg-4 col-md-6">
                  <div className="single-blog">
                    <div className="blog-image">
                      <Link to="blog-details.html">
                        <img src="assets/images/blog/blog-1.jpg" alt="" />
                      </Link>
                      <div className="top-meta">
                        <span className="date">
                          <span>08</span>Aug
                        </span>
                      </div>
                    </div>
                    <div className="blog-content">
                      <div className="blog-meta">
                        <span>
                          <i className="fas fa-user" />{" "}
                          <Link to="#">Andrew Paker</Link>
                        </span>
                        <span>
                          <i className="far fa-comments" /> 0 Comments
                        </span>
                      </div>
                      <h3 className="title">
                        <Link to="blog-details.html">
                          How to become a successful businessman{" "}
                        </Link>
                      </h3>
                      <div className="blog-btn">
                        <Link className="blog-btn-link" to="blog-details.html">
                          Read Full{" "}
                          <i className="fas fa-long-arrow-alt-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* Blog End */}

        <Footer />
      </div>
    </>
  );
}

export default Home;
