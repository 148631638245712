import React from 'react'
import { Link } from 'react-router-dom'

const ServiceCard = ({imageIcon,title,description,linkTo}) => {
  return (
    <>
    <div className="col-xl-3 col-md-6">
                  {/* Service Item Start */}
                  <div className="service-item-03">
                    <div className="service-img">
                      <img src={imageIcon} alt={title} />
                    </div>
                    <div className="service-content">
                      <h3 className="title">
                        <Link to={linkTo}>{title}</Link>
                      </h3>
                      <p>
                        {description}
                      </p>
                      <div className="read-more">
                        <Link to={linkTo}>
                          <i className="fas fa-plus" /> Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* Service Item End */}
                </div>
    </>
  )
}

export default ServiceCard
