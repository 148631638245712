import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
export default function MobileHeader() {
  
  return (
    <>
      {/* Header Start  */}
      <div className="offcanvas offcanvas-start" id="offcanvasExample">
        <div className="offcanvas-header">
          {/* Offcanvas Logo Start */}
          <div className="offcanvas-logo">
            <Link to="/">
              <img src={"assets/images/appdoorlogoclaen.png"} alt="Appdoor" />
            </Link>
          </div>
          {/* Offcanvas Logo End */}
          <button
            type="button"
            className="close-btn"
            data-bs-dismiss="offcanvas"
          >
            <i className="flaticon-close" />
          </button>
        </div>
        {/* Offcanvas Body Start */}
        <div className="offcanvas-body">
          <div className="offcanvas-menu">
            <ul className="main-menu">
              <li className="active-menu">
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">Aboute Us</Link>
              </li>
              <li>
                <Link to="#">Services</Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="#">Software Development</Link>
                  </li>
                  <li>
                    <Link to="#">Website Development</Link>
                  </li>
                  <li>
                    <Link to="#">
                      Mobile App Development
                    </Link>
                  </li>
                  <li>
                    <Link to="#">Digital Marketing</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/products">Products</Link>
              </li>
              <li>
                <Link to="/portfolio">Portfolio</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
        </div>
        {/* Offcanvas Body End */}
      </div>
      {/* Header End */}
    </>
  );
}
