import React,  { useState, useEffect }  from 'react';
import { Link, useLocation } from "react-router-dom";
export default function Header(props) {
  console.log(props);
  const [activeHome, setActiveHome] = useState('');
  const [activeAbout, setActiveAbout] = useState('');
  const [activeServices, setActiveServices] = useState('');
  const [activeProducts, setActiveProducts] = useState('');
  const [activePrtfolio, setActivePrtfolio] = useState('');
  const [activeContact, setActiveContact] = useState('');

  useEffect(() => {
    if(props.activePage==='home'){
      setActiveHome("active-menu");
    }
    if(props.activePage==='about'){
      setActiveAbout("active-menu");
    }
    if(props.activePage==='contact'){
      setActiveContact("active-menu");
    }
    if(props.activePage==='portfolio'){
      setActivePrtfolio("active-menu");
    }
    
    
  }, []);

  
  
  
  return (
        <>
          {/* Header Start  */}
          <div id="header" className="section header-section">
            <div className="container">
              {/* Header Wrap Start  */}
              <div className="header-wrap">
                <div className="header-logo">
                  <Link to="/">
                    <img src={"assets/images/appdoorlogoclaen.png"} alt="Appdoor" />
                  </Link>
                </div>
                <div className="header-menu d-none d-lg-block">
                  <ul className="main-menu">
                    <li className={activeHome}>
                      <Link to="/">Home</Link>
                    </li>
                    <li className={activeAbout}>
                      <Link to="/about" >Aboute Us</Link>
                    </li>
                    <li className={activeServices}>
                      <Link to="">Services</Link>
                      <ul className="sub-menu">
                        <li>
                          <Link to="#">Software Development</Link>
                        </li>
                        <li>
                          <Link to="#">Website Development</Link>
                        </li>
                        <li>
                          <Link to="#">Mobile App Development</Link>
                        </li>
                        <li>
                          <Link to="#">Digital Marketing</Link>
                        </li>
                      </ul>
                    </li>
                    <li className={activeProducts}>
                      <Link to="/products">Products</Link>
                    </li>
                    <li className={activePrtfolio}>
                      <Link to="/portfolio">Portfolio</Link>
                    </li>
                    <li className={activeContact}>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </div>

                <div className="header-meta">
                       
                        {/* <!-- Header Toggle Start --> */}
                        <div className="header-toggle d-lg-none">
                            <button data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample">
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                        </div>
                        {/* <!-- Header Toggle End --> */}
                    </div>

              </div>
              {/* Header Wrap End  */}
            </div>
          </div>
          {/* Header End */}
        </>
      
  )
}
