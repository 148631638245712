import React from 'react'
import { Link } from 'react-router-dom'

const AboutUsServiceCard = ({serviceImg,serviceTitle,description,linkto}) => {
  return (
    <>
    <div className="col-xl-3 col-lg-4 col-md-6">
                    <div className="choose-us-item-02">
                      <div className="choose-us-img">
                        <img src={serviceImg} alt={serviceTitle} />
                      </div>
                      <div className="choose-us-content">
                        <h3 className="title">
                          <Link to={linkto}>
                            {serviceTitle}
                          </Link>
                        </h3>
                        <p>
                          {description}
                        </p>
                      </div>
                    </div>
                  </div>
                  </>
  )
}

export default AboutUsServiceCard
