import React from 'react'
import { Link } from 'react-router-dom'

const Footer = ({showContact=true}) => {
  return (
    <>
     {/* Cta Start */}
     {showContact ? <div className="section techwix-cta-section-02">
          <div className="container">
            {/* Cta Wrap Start */}
            <div
              className="cta-wrap"
              style={{ backgroundImage: "url(assets/images/bg/cta-bg.jpg)" }}
            >
              <div className="row align-items-center">
                <div className="col-xl-9 col-lg-8">
                  {/* Cta Content Start */}
                  <div className="cta-content">
                    <div className="cta-icon">
                      <img src="assets/images/cta-icon2.png" alt="image not fond" />
                    </div>
                    <p>We're Conveying the best client Experience</p>
                  </div>
                  {/* Cta Content End */}
                </div>
                <div className="col-xl-3 col-lg-4">
                  {/* Cta Button Start */}
                  <div className="cta-btn">
                    <Link className="btn btn-white" to="#" style={{width:" max-content",}}>
                      +91 93299 32627
                    </Link>
                  </div>
                  {/* Cta Button End */}
                </div>
              </div>
            </div>
            {/* Cta Wrap End */}
          </div>
        </div> : ''}
     
        {/* Cta End */}
     {/* Footer Section Start */}
     <div
          className="section footer-section footer-section-03"
          style={{ backgroundImage: "url(assets/images/bg/footer-bg.jpg)" }}
        >
          <div className="container">
            {/* Footer Widget Wrap Start */}
            <div className="footer-widget-wrap">
              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  {/* Footer Widget Start */}
                  <div className="footer-widget-about">
                    <Link className="footer-logo" to="index">
                      <img src={"assets/images/appdoorlogoclaen.png"} alt="AppDoor" />
                    </Link>
                    <p>
                    Welcome to Appdoor, where innovation meets excellence! We are a passionate team of tech enthusiasts on a mission to redefine the digital landscape and empower businesses with cutting-edge solutions.
                    </p>
                    <div className="footer-social">
                      <ul className="social">
                        <li>
                          <Link to="https://www.facebook.com/appdoorbsp" target="_blank">
                            <i className="fab fa-facebook-f" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-twitter" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-linkedin-in" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* Footer Widget End */}
                </div>
                <div className="col-lg-3 col-sm-6">
                  {/* Footer Widget Start */}
                  <div className="footer-widget">
                    <h4 className="footer-widget-title">Useful Links</h4>
                    <div className="widget-link">
                      <ul className="link">
                        <li>
                          <Link to="#">Terms &amp; Conditions</Link>
                        </li>
                        <li>
                          <Link to="about">About Company</Link>
                        </li>
                        <li>
                          <Link to="#">FAQ's</Link>
                        </li>
                        <li>
                          <Link to="#">Policy</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* Footer Widget End */}
                </div>
                <div className="col-lg-3 col-sm-6">
                  {/* Footer Widget Start */}
                  <div className="footer-widget">
                    <h4 className="footer-widget-title">Our Services</h4>
                    <div className="widget-link">
                      <ul className="link">
                        <li>
                          <Link to="#">Software Development</Link>
                        </li>
                        <li>
                          <Link to="#">Website Development</Link>
                        </li>
                        <li>
                          <Link to="#">Mobile App Development</Link>
                        </li>
                        <li>
                          <Link to="#">Digital Marketing</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* Footer Widget End */}
                </div>
                <div className="col-lg-3 col-sm-6">
                  {/* Footer Widget Start */}
                  <div className="footer-widget">
                    <h4 className="footer-widget-title">Contact Information</h4>
                    <div className="widget-info">
                      <ul>
                        <li>
                          <div className="info-icon">
                            <i className="flaticon-phone-call" />
                          </div>
                          <div className="info-text">
                            <span>
                              <Link to="#">+91 93299 32627</Link>
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="info-icon">
                            <i className="far fa-envelope-open" />
                          </div>
                          <div className="info-text">
                            <span>
                              <Link to="#">appdoorbsp@gmail.com</Link>
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="info-icon">
                            <i className="flaticon-pin" />
                          </div>
                          <div className="info-text">
                            <span>Park avenue complex 1 floor ,vyapar Vihar
                                      ,Bilaspur.</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* Footer Widget End */}
                </div>
              </div>
            </div>
            {/* Footer Widget Wrap End */}
          </div>
          {/* Footer Copyright Start */}
          <div className="footer-copyright-area">
            <div className="container">
              <div className="footer-copyright-wrap">
                <div className="row align-items-center">
                  <div className="col-lg-12">
                    {/* Footer Copyright Text Start */}
                    <div className="copyright-text text-center">
                      <p>© Copyrights appdoor All rights reserved. </p>
                    </div>
                    {/* Footer Copyright Text End */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Footer Copyright End */}
        </div>
        {/* Footer Section End */}
     {/* back to top start */}
     <div className="progress-wrap">
          <svg
            className="progress-circle svg-content"
            width="100%"
            height="100%"
            viewBox="-1 -1 102 102"
          >
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
          </svg>
        </div>
        {/* back to top end */}
    </>
  )
}

export default Footer
