import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import MobileHeader from "../components/mobileHeader";
import { Link } from "react-router-dom";
import Banner from "../components/homepage/banner";
import ServiceCard from "../components/homepage/serviceCard";
import Footer from "../components/footer";
import {Helmet} from "react-helmet";


function Home() {

  return (
    <>
      <div className="main-wrapper">
      <Helmet>
                <meta charSet="utf-8" />
                <title>Appdoor | think digital</title>
                <link rel="canonical" href="https://appdoor.co.in/" />
                <meta name="description" content="Appdoor | think digital - Welcome to Appdoor, where innovation meets excellence! We are a passionate team of tech enthusiasts on a mission to redefine the digital landscape and empower businesses with cutting-edge solutions." />
            </Helmet>
        {/* <Preloader/> */}

        <Header activePage={"home"} />

        {/* Offcanvas Start*/}
        <MobileHeader />
        {/* Offcanvas End */}
        {/* Hero Start */}
        <Banner />
        {/* Hero End */}
        {/* Service Start */}
        <div
          className="section techwix-service-section-03"
          style={{ backgroundImage: "url(assets/images/bg/service-bg3.jpg)" }}
        >
          <div className="container">
            {/* Service Wrap Start */}
            <div className="service-wrap-03">
              <div className="row">
                <ServiceCard
                  imageIcon="assets/images/ser-icon9.png"
                  title="Software Development"
                  description="Software development is creating computer programs or applications through coding, testing, and maintenance to address specific needs and solve problems."
                  linkTo="#"
                />
                <ServiceCard
                  imageIcon="assets/images/ser-icon10.png"
                  title="Website Development"
                  description="
                  Website development is creating, designing, and building websites. It involves coding, layout design, and content integration to create functional web pages."
                  linkTo="#"
                />
                <ServiceCard
                  imageIcon="assets/images/ser-icon11.png"
                  title="Mobile App Development"
                  description="Mobile app development is the process of creating software applications for mobile devices, encompassing design, coding, testing, and deployment."
                  linkTo="#"
                />
                <ServiceCard
                  imageIcon="assets/images/ser-icon12.png"
                  title="Digital Marketing"
                  description="Digital marketing is promoting products or services using online channels, such as social media, SEO, email, and paid advertising, to reach and engage a target audience."
                  linkTo="#"
                />
              </div>
            </div>
            {/* Service Wrap End */}
          </div>
        </div>
        {/* Service End */}
        {/* About Start */}
        <div className="section techwix-about-section-04 section-padding">
          <div className="shape-1" />
          <div className="container">
            {/* About Wrapper Start */}
            <div className="about-wrap">
              <div className="row">
                <div className="col-lg-6">
                  {/* About Content Wrap Start */}
                  <div className="about-content-wrap">
                    <div className="section-title">
                      <h3 className="sub-title">Who we are</h3>
                      <h2 className="title">
                        Profoundly Custom-made IT Plan, The executives and
                        Backing Administrations.
                      </h2>
                    </div>
                    <p className="text">
                      Speed up advancement with elite tech groups We'll match
                      you to a whole remote group of inconceivable independent
                      ability for all your product improvement needs.
                    </p>
                    {/* About Author Info Wrap Start */}
                    <div className="about-author-info-wrap">
                      <div className="about-info">
                        <p>Call to ask any question </p>
                        <h3 className="number">+91-93299-32627</h3>
                      </div>
                    </div>
                    {/* About Author Info Wrap End */}
                  </div>
                  {/* About Content Wrap End */}
                </div>
                <div className="col-lg-6">
                  {/* About Image Wrap Start */}
                  <div className="about-img-wrap">
                    {/* <div className="play-btn-02">
                      <Link
                        className="popup-video"
                        to="https://www.youtube.com/watch?time_continue=3&v=_X0eYtY8T_U"
                      >
                        <i className="fas fa-play" />
                      </Link>
                    </div> */}
                    <div className="about-img about-img-big">
                      <img src="assets/images/about-big2.jpg" alt="" />
                    </div>
                    <div className="about-img about-img-sm">
                      <img src="assets/images/about-sm2.jpg" alt="" />
                    </div>
                  </div>
                  {/* About Image Wrap End */}
                </div>
              </div>
            </div>
            {/* About Wrapper End */}
          </div>
        </div>
        {/* About End */}
        {/* Counter Start */}
        <div className="section techwix-counter-section-02">
          <div className="container">
            <div className="counter-wrap">
              <div className="row">
                <div className="col-xl-3 col-md-6">
                  {/* Single Counter Start */}
                  <div className="single-counter">
                    <div className="counter-img">
                      <img src="assets/images/counter-1.png" alt="" />
                    </div>
                    <div className="counter-content">
                      <span className="counter">25</span>
                      <p>Happy clients</p>
                    </div>
                  </div>
                  {/* Single Counter End */}
                </div>
                <div className="col-xl-3 col-md-6">
                  {/* Single Counter Start */}
                  <div className="single-counter">
                    <div className="counter-img">
                      <img src="assets/images/counter-2.png" alt="" />
                    </div>
                    <div className="counter-content">
                      <span className="counter">42</span>
                      <p>Finished projects</p>
                    </div>
                  </div>
                  {/* Single Counter End */}
                </div>
                <div className="col-xl-3 col-md-6">
                  {/* Single Counter Start */}
                  <div className="single-counter">
                    <div className="counter-img">
                      <img src="assets/images/counter-3.png" alt="" />
                    </div>
                    <div className="counter-content">
                      <span className="counter">7</span>
                      <p>Skilled Experts</p>
                    </div>
                  </div>
                  {/* Single Counter End */}
                </div>
                <div className="col-xl-3 col-md-6">
                  {/* Single Counter Start */}
                  <div className="single-counter single-counter-4">
                    <div className="counter-img">
                      <img src="assets/images/counter-1.png" alt="" />
                    </div>
                    <div className="counter-content">
                      <span className="counter">0</span>
                      <p>Media Posts</p>
                    </div>
                  </div>
                  {/* Single Counter End */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Counter End */}
        {/* Choose Us Start */}
        <div
          className="section techwix-choose-us-section section-padding"
          style={{
            backgroundImage: "url(assets/images/bg/choose-us-bg.jpg)",
          }}
        >
          <div className="container">
            {/* Choose Us Wrap Start */}
            <div className="choose-us-wrap">
              <div className="section-title text-center">
                <h3 className="sub-title">REASON TO CHOOSE US</h3>
                <h2 className="title">
                  We give really noticeable IT arrangements.
                </h2>
              </div>
              <div className="choose-us-content-wrap">
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    {/* Choose Us Item Start */}
                    <div className="choose-us-item">
                      <div className="choose-us-img">
                        <Link to="about">
                          <img
                            src="assets/images/choose-us1.jpg"
                            alt="Innovation at the Core"
                          />
                        </Link>
                        <div className="choose-us-content">
                          <h3 className="title">Innovation at the Core</h3>
                          <p>
                            We're driven by innovation, constantly pushing
                            boundaries to create cutting-edge solutions that
                            cater to your unique needs.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* Choose Us Item End */}
                  </div>
                  <div className="col-lg-4 col-md-6">
                    {/* Choose Us Item Start */}
                    <div className="choose-us-item">
                      <div className="choose-us-img">
                        <Link to="about">
                          <img
                            src="assets/images/choose-us2.jpg"
                            alt="Expertise Matters"
                          />
                        </Link>
                        <div className="choose-us-content">
                          <h3 className="title">Expertise Matters</h3>
                          <p>
                            Our team comprises skilled professionals with
                            diverse expertise, ensuring that every project we
                            undertake is executed flawlessly.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* Choose Us Item End */}
                  </div>
                  <div className="col-lg-4 col-md-6">
                    {/* Choose Us Item Start */}
                    <div className="choose-us-item">
                      <div className="choose-us-img">
                        <Link to="about">
                          <img
                            src="assets/images/choose-us3.jpg"
                            alt="Client-Centric Approach"
                          />
                        </Link>
                        <div className="choose-us-content">
                          <h3 className="title">Client-Centric Approach</h3>
                          <p>
                            Your success is our priority. We listen, understand,
                            and collaborate closely with you to deliver tailored
                            solutions that exceed your expectations.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* Choose Us Item End */}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="more-choose-content text-center">
                      <p>
                        Learn more about{" "}
                        <Link to="about">
                          More reason{" "}
                          <i className="fas fa-long-arrow-alt-right" />
                        </Link>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Choose Us Wrap End */}
          </div>
        </div>
        {/* Choose Us End */}
        {/* Skill Start */}
        <div className="section techwix-skill-section-02 section-padding">
          <div className="container">
            <div className="skill-wrap">
              <div className="row">
                <div className="col-lg-6">
                  {/* Skill Left Start */}
                  <div className="skill-left">
                    <div className="section-title">
                      <h2 className="title">
                        Planning for your prosperity, we give really noticeable
                        IT arrangements
                      </h2>
                    </div>
                    <div className="experience-wrap">
                      <div className="experience">
                        <h2 className="number">13</h2>
                        <span>
                          Years of <br /> experience
                        </span>
                      </div>
                      <div className="experience-text">
                        <p>
                        Join hands with Appdoor, and together, we'll transform your vision into reality. Experience the power of technology like never before.
                        </p>
                        <Link className="learn-more" to="about">
                          learn More About Us{" "}
                          <i className="fas fa-long-arrow-alt-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* Skill Left End */}
                </div>
                <div className="col-lg-6">
                  {/* Skill Right Start */}
                  <div className="skill-right">
                    <div className="counter-bar">
                      {/* Skill Item Start */}
                      <div className="skill-item">
                        <span className="title">Software Development</span>
                        <div className="skill-bar">
                          <div className="bar-inner">
                            <div
                              className="bar progress-line color-1"
                              data-width={80}
                            >
                              <span className="skill-percent">
                                <span className="counter">80</span>%
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Skill Item End */}
                      {/* Skill Item Start */}
                      <div className="skill-item">
                        <span className="title">Website Development</span>
                        <div className="skill-bar">
                          <div className="bar-inner">
                            <div
                              className="bar progress-line color-1"
                              data-width={95}
                            >
                              <span className="skill-percent">
                                <span className="counter">95</span>%
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Skill Item End */}
                      {/* Skill Item Start */}
                      <div className="skill-item">
                        <span className="title">Mobile App Development</span>
                        <div className="skill-bar">
                          <div className="bar-inner">
                            <div
                              className="bar progress-line color-1"
                              data-width={80}
                            >
                              <span className="skill-percent">
                                <span className="counter">80</span>%
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Skill Item End */}
                      {/* Skill Item Start */}
                      <div className="skill-item">
                        <span className="title">Digital Marketing</span>
                        <div className="skill-bar">
                          <div className="bar-inner">
                            <div
                              className="bar progress-line color-1"
                              data-width={90}
                            >
                              <span className="skill-percent">
                                <span className="counter">90</span>%
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Skill Item End */}
                    </div>
                  </div>
                  {/* Skill Right End */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Skill End */}
        {/* Case Study Start */}
        <div
          className="section techwix-case-study-section-02 section-padding"
          style={{
            backgroundImage: "url(assets/images/bg/case-study-bg.jpg)",
          }}
        >
          <div className="container">
            <div className="case-study-wrap">
              <div className="section-title text-center">
                <h3 className="sub-title">From our Case studies</h3>
                <h2 className="title white">We delivered best solution</h2>
              </div>
            </div>
          </div>
          {/* Case Study Content Wrap Start */}
          <div className="case-study-content-wrap">
            <div className="swiper-container case-study-active">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  {/* Single Case Study Start */}
                  <div className="single-case-study-02">
                    <div className="case-study-img">
                      <Link to="#">
                        <img src="assets/images/case-4.jpg" alt="Quality Assurance" />
                      </Link>
                    </div>
                    <div className="case-study-content">
                      <h3 className="title">
                        <Link to="#">
                          <span>Quality</span> <br />{" "}
                          <span>Assurance</span>
                        </Link>
                      </h3>
                      <p className="text">
                      We adhere to the highest industry standards, rigorously testing and refining our work to guarantee top-notch quality and reliability.
                      </p>
                    </div>
                  </div>
                  {/* Single Case Study End */}
                </div>
                <div className="swiper-slide">
                  {/* Single Case Study Start */}
                  <div className="single-case-study-02">
                    <div className="case-study-img">
                      <Link to="#">
                        <img src="assets/images/case-5.jpg" alt="Timely Deliver" />
                      </Link>
                    </div>
                    <div className="case-study-content">
                      <h3 className="title">
                        <Link to="#">
                          <span>Timely </span> <br />{" "}
                          <span>Deliver</span>
                        </Link>
                      </h3>
                      <p className="text">
                      We understand the value of your time. Our efficient project management ensures on-time delivery without compromising quality.
                      </p>
                    </div>
                  </div>
                  {/* Single Case Study End */}
                </div>
                <div className="swiper-slide">
                  {/* Single Case Study Start */}
                  <div className="single-case-study-02">
                    <div className="case-study-img">
                      <Link to="#">
                        <img src="assets/images/case-6.jpg" alt="Scalability" />
                      </Link>
                    </div>
                    <div className="case-study-content">
                      <h3 className="title">
                        <Link to="#">
                          <span>Scalability</span> <br />{" "}
                        </Link>
                      </h3>
                      <p className="text">
                      Our solutions are designed to grow with your business, providing the scalability needed to adapt and thrive in a dynamic digital landscape.
                      </p>
                    </div>
                  </div>
                  {/* Single Case Study End */}
                </div>
              </div>
              {/* Add Pagination */}
              <div className="swiper-pagination" />
            </div>
          </div>
          {/* Case Study Content Wrap End */}
        </div>
        {/* Case Study End */}
        {/* Testimonial Start  */}
        <div className="section techwix-testimonial-section-02 techwix-testimonial-section-03 section-padding-02">
          <div className="container">
            {/* Testimonial Wrap Start  */}
            <div className="testimonial-wrap-02">
              <div className="section-title text-center">
                <h3 className="sub-title">Testimonial</h3>
                <h2 className="title">Our client's words</h2>
              </div>
              <div className="testimonial-content-wrap-02">
                <div className="swiper-container testimonial-02-active">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      {/*  Single Testimonial Start  */}
                      <div className="single-testimonial-02">
                        <div className="testimonial-thumb">
                          <img src="" alt="John D." />
                        </div>
                        <div className="testimonial-content">
                          <img src="assets/images/testi-icon.png" alt="John D." />
                          <p>
                          "I can't thank Appdoor enough for their outstanding work! Their team transformed our app idea into a polished, user-friendly reality. Their attention to detail and commitment to quality are unparalleled."
                          </p>
                          <span className="name">John D. </span>
                        </div>
                      </div>
                      {/*  Single Testimonial End  */}
                    </div>
                    <div className="swiper-slide">
                      {/*  Single Testimonial Start  */}
                      <div className="single-testimonial-02">
                        <div className="testimonial-thumb">
                          <img src="" alt="Sarah M." />
                        </div>
                        <div className="testimonial-content">
                          <img src="assets/images/testi-icon.png" alt="Sarah M." />
                          <p>
                          "Appdoor's expertise is unmatched. They not only built us a powerful e-commerce website but also provided invaluable insights to improve our online presence. Working with them was a game-changer."
                          </p>
                          <span className="name">Sarah M. </span>
                        </div>
                      </div>
                      {/*  Single Testimonial End  */}
                    </div>
                    <div className="swiper-slide">
                      {/*  Single Testimonial Start  */}
                      <div className="single-testimonial-02">
                        <div className="testimonial-thumb">
                          <img src="" alt="David L." />
                        </div>
                        <div className="testimonial-content">
                          <img src="assets/images/testi-icon.png" alt="David L." />
                          <p>
                          "Choosing Appdoor was the best decision we made for our startup. They delivered our project ahead of schedule and within budget. Their communication throughout the process was exceptional."
                          </p>
                          <span className="name">David L. </span>
                        </div>
                      </div>
                      {/*  Single Testimonial End  */}
                    </div>
                  </div>
                  {/* Add Pagination */}
                  <div className="swiper-pagination" />
                </div>
              </div>
            </div>
            {/* Testimonial Wrap End  */}
          </div>
        </div>
        {/* Testimonial End  */}
        {/* Brand Logo Start */}
        <div className="section techwix-brand-section techwix-brand-section-03">
          <div className="container">
            {/* Brand Wrapper Start */}
            <div className="brand-wrapper text-center">
              {/* Brand Active Start */}
              <div className="brand-active">
                <div className="swiper-container">
                  <div className="swiper-wrapper">
                    {/* Single Brand Start */}
                    <div className="swiper-slide single-brand">
                      <img src="assets/images/odclogo.PNG" alt="Odyssey Motors Pvt. Ltd." />
                    </div>
                  </div>
                </div>
              </div>
              {/* Brand Active End */}
            </div>
            {/* Brand Wrapper End */}
          </div>
        </div>
        {/* Brand Logo End */}
        {/* Team Start */}
        <div className="section techwix-team-section section-padding-02">
          <div className="container">
            {/* Team Wrap Start */}
            <div className="team-wrap">
              <div className="section-title text-center">
                <h3 className="sub-title">Our expert team </h3>
                <h2 className="title"> We have an expert team</h2>
              </div>
              {/* Team Content Wrap Start */}
              <div className="team-content-wrap">
                <div className="swiper-container team-active">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      {/* Single Team Start */}
                      {/* <div className="single-team">
                        <div className="team-img">
                          <Link to="team.html">
                            <img src="assets/images/team/team-1.jpg" alt="" />
                          </Link>
                        </div>
                        <div className="team-content">
                          <h3 className="name">
                            <Link to="team.html">
                              Andrew <br /> Max Fetcher
                            </Link>
                          </h3>
                          <span className="designation">CEO, techwix</span>
                          <div className="team-social">
                            <ul className="social">
                              <li>
                                <Link to="#">
                                  <i className="fab fa-facebook-f" />
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fab fa-twitter" />
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fab fa-dribbble" />
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fab fa-behance" />
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div> */}
                      {/* Single Team End */}
                    </div>
                  </div>
                  {/* Add Pagination */}
                  <div className="swiper-pagination" />
                </div>
              </div>
              {/* Team Content Wrap End */}
            </div>
            {/* Team Wrap End */}
          </div>
        </div>
        {/* Team End */}
        {/* Blog Start */}
        <div className="section techwix-blog-section section-padding-02">
          <div className="container">
            {/* Section Title Start */}
            <div className="section-title text-center">
              <h4 className="sub-title">latest Blog</h4>
              <h2 className="title">From the newsroom</h2>
            </div>
            {/* Section Title End */}
            <div className="techwix-blog-wrap">
              <div className="row">
                {/* <div className="col-lg-4 col-md-6">
                  <div className="single-blog">
                    <div className="blog-image">
                      <Link to="blog-details.html">
                        <img src="assets/images/blog/blog-1.jpg" alt="" />
                      </Link>
                      <div className="top-meta">
                        <span className="date">
                          <span>08</span>Aug
                        </span>
                      </div>
                    </div>
                    <div className="blog-content">
                      <div className="blog-meta">
                        <span>
                          <i className="fas fa-user" />{" "}
                          <Link to="#">Andrew Paker</Link>
                        </span>
                        <span>
                          <i className="far fa-comments" /> 0 Comments
                        </span>
                      </div>
                      <h3 className="title">
                        <Link to="blog-details.html">
                          How to become a successful businessman{" "}
                        </Link>
                      </h3>
                      <div className="blog-btn">
                        <Link className="blog-btn-link" to="blog-details.html">
                          Read Full{" "}
                          <i className="fas fa-long-arrow-alt-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div> */}
                
              </div>
            </div>
          </div>
        </div>
        {/* Blog End */}
       
       
       <Footer />
      </div>
    </>
  );
}

export default Home;
