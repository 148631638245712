import React from "react";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
const NoPage = () => {
  return (
    <>
    <Helmet>
                <meta charSet="utf-8" />
                <title>404 - Appdoor | think digital</title>
                <link rel="canonical" href="https://appdoor.co.in/" />
                <meta name="description" content="Appdoor | think digital - Welcome to Appdoor, where innovation meets excellence! We are a passionate team of tech enthusiasts on a mission to redefine the digital landscape and empower businesses with cutting-edge solutions." />
                <link rel="stylesheet" href="assets/css/404.css"></link>
            </Helmet>
      <section className="page_404">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 ">
              <div className="col-sm-10 col-sm-offset-1  text-center">
                <div className="four_zero_four_bg">
                  <h1 className="text-center ">404</h1>
                </div>

                <div className="contant_box_404">
                  <h3 className="h2">Look like you're lost</h3>

                  <p>the page you are looking for not avaible!</p>

                  <Link to="/" className="link_404">
                    Go to Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NoPage;
