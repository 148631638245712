import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import MobileHeader from "../components/mobileHeader";
import { Link } from "react-router-dom";
import Banner from "../components/homepage/banner";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";
import CommonBanner from "../components/commonBanner";
import AboutUsServiceCard from "../components/abputUs/aboutUsServiceCard";

const ContactUs = () => {


  return (
    <>
      <div className="main-wrapper">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Contact Us | AppDoor | think digital</title>
          <link rel="canonical" href="https://appdoor.co.in/" />
          <meta
            name="description"
            content="Appdoor | think digital - Welcome to Appdoor, where innovation meets excellence! We are a passionate team of tech enthusiasts on a mission to redefine the digital landscape and empower businesses with cutting-edge solutions."
          />
        </Helmet>

        <Header activePage={"contact"} />

        {/* Offcanvas Start*/}
        <MobileHeader />
        {/* Offcanvas End */}

        <CommonBanner bannerTitle="Contact Us" />

        <div className="section contact-info-section section-padding-02">
            <div className="container">
                <div className="contact-info-wrap">
                    <div className="row">
                        <div className="col-lg-4 col-sm-6">
                            <div className="single-contact-info text-center">
                                <div className="info-icon">
                                    <img src="assets/images/info-1.png" alt=""/>
                                </div>
                                <div className="info-content">
                                    <h5 className="title">Give us a call</h5>
                                    <p>(+91) 93299-32627</p>
                                    <p>(+91) 07746-860000</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="single-contact-info text-center">
                                <div className="info-icon">
                                    <img src="assets/images/info-2.png" alt=""/>
                                </div>
                                <div className="info-content">
                                    <h5 className="title">Drop us a line</h5>
                                    <p>appdoorbsp@gmail.com</p>
                                    <p>info@appdoor.co.in</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="single-contact-info text-center">
                                <div className="info-icon">
                                    <img src="assets/images/info-3.png" alt=""/>
                                </div>
                                <div className="info-content">
                                    <h5 className="title">Visit our office</h5>
                                    <p>Park avenue complex 1 floor ,vyapar Vihar ,Bilaspur.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="section techwix-contact-section techwix-contact-section-02 techwix-contact-section-03 section-padding-02">
            <div className="container">
                <div className="contact-wrap">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="contact-form">
                                <div className="contact-form-wrap">
                                    <div className="heading-wrap text-center">
                                        <span className="sub-title"> request a quote</span>
                                        <h3 className="title">How May We Help You!</h3>
                                    </div>
                                    <form action="#" method="POST">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="single-form">
                                                    <input type="text" placeholder="Name *" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="single-form">
                                                    <input type="email" placeholder="Email *" />
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="single-form">
                                                    <input type="text" placeholder="Subject *" />
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="single-form">
                                                    <textarea placeholder="Write A Message"></textarea>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="form-btn">
                                                    <button className="btn" type="submit">Send Message</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section contact-map-section">
            <div class="contact-map-wrap">
                <iframe scrolling="no" marginheight="0" marginwidth="0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d231.0973944040131!2d82.14643474789867!3d22.066485278899602!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a280b46deb2ca05%3A0xf145ef58a4bf47fe!2sPark%20Avenue!5e0!3m2!1sen!2sin!4v1694169896958!5m2!1sen!2sin" title="Appdoor" aria-label="Appdoor" frameborder="0"></iframe>
            </div>
        </div>


        <Footer showContact={false} />
      </div>
    </>
  );
};

export default ContactUs;
