import About from "./pages/About";
import ContactUs from "./pages/ContactUs";
import Home from "./pages/Home";
import NoPage from "./pages/NoPage";

import { BrowserRouter, Route, Routes, Switch,Router } from "react-router-dom";

import { ErrorBoundary } from "react-error-boundary";
import React, { useState, useEffect } from "react";
import Portfolio from "./pages/Portfolio";
import Products from "./pages/Products";

function loadjsFile(url) {
  const script = document.createElement("script");
  script.src = url;
  script.async = true;
  return script;
}
function loadJsUrl() {
  document.body.appendChild(
    loadjsFile("assets/js/vendor/jquery-1.12.4.min.js")
  );
  document.body.appendChild(
    loadjsFile("assets/js/vendor/modernizr-3.11.2.min.js")
  );
  document.body.appendChild(loadjsFile("assets/js/plugins/popper.min.js"));
  document.body.appendChild(loadjsFile("assets/js/plugins/bootstrap.min.js"));
  document.body.appendChild(
    loadjsFile("assets/js/plugins/swiper-bundle.min.js")
  );
  document.body.appendChild(loadjsFile("assets/js/plugins/aos.js"));
  document.body.appendChild(loadjsFile("assets/js/plugins/waypoints.min.js"));
  document.body.appendChild(loadjsFile("assets/js/plugins/back-to-top.js"));
  document.body.appendChild(
    loadjsFile("assets/js/plugins/jquery.counterup.min.js")
  );
  document.body.appendChild(loadjsFile("assets/js/plugins/appear.min.js"));
  document.body.appendChild(
    loadjsFile("assets/js/plugins/jquery.magnific-popup.min.js")
  );
  document.body.appendChild(loadjsFile("assets/js/main.js"));
}


function App() {
  useEffect(() => {
    try {
      loadJsUrl();
    } catch (error) {
      window.location.reload();
    }
  });
  return (
    <>
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error) => {
        // You can also log the error to an error reporting service like AppSignal
        // logErrorToMyService(error, errorInfo);
        console.error(error);
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" >
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<ContactUs />} />
            <Route path="portfolio" element={<Portfolio />} />
            <Route path="products" element={<Products />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
      </ErrorBoundary>
    </>
  );
}

const ErrorFallback = ({ error }) => (
  <div>
    <p>Something went wrong 😭</p>
 
    {error.message && <span>Here's the error: {error.message}</span>}
  </div>
);

export default App;
